import React from 'react';
import { useStoreState } from 'easy-peasy';
import qs from 'querystringify';

import Layout from '../../../components/global/Layout';

import Header from './Header';

import TabSection from './TabSection';

import FilterSection from './FilterSection';

import StatisticSection from './StatisticSection';

import BookingManagerTable from './BookingManagerTable';

import {
  useTableBookingByTimeSlotsData,
  useTableBookingsData,
} from './helpers';

import { Pagination } from '../../../components/elements';

import TagFilterModal from './TagFilterModal';
import StatusFilterModal from './StatusFilters';
import ChartsScreen from './ChartsScreen';
import { useTableSetting } from '../TableBookingSetting/helpers';

import BookingSessions from './BookingSessions';

const useRefState = refValue => {
  const initialiseRef = React.useRef(true);
  const [refState, setRefState] = React.useState();

  if (initialiseRef.current && refValue) {
    setRefState(refValue);
    initialiseRef.current = false;
  }
  return [refState, setRefState];
};

const TableBookingManager = ({ history, match, location }) => {
  const { userId: adminUserId } = useStoreState(state => state.auth);
  const [placeId, ,] = useRefState(match.params.placeId);

  const [tableSettingProps] = useTableSetting({ placeId });

  const routeState = qs.parse(location.search);
  let { page, tab, tags, bookingStatusFilter, guestStatusFilter } = routeState;

  if (bookingStatusFilter) {
    // pass
  } else {
    bookingStatusFilter = '';
  }

  if (guestStatusFilter) {
    // pass
  } else {
    guestStatusFilter = '';
  }

  if (tags) {
    tags = atob(tags).split(',');
  } else {
    tags = [];
  }

  if (page) {
    page = parseInt(page, 10);
  } else {
    page = 1;
  }

  if (tab) {
    tab = parseInt(tab, 10);
  } else {
    tab = 0;
  }

  const [dateFilter, setDateFilter] = React.useState(new Date());

  const [sort, setSort] = React.useState('');
  React.useEffect(() => {
    if (tab !== 0) {
      setDateFilter(new Date());
    }
  }, [tab]);

  const [tagFilterModalActive, setTagFilterModalActive] = React.useState(false);
  const [
    statusFilterModalIsActive,
    setStatusFilterModalIsActive,
  ] = React.useState(false);
  const [
    chartsScreenModalIsActive,
    setChartsScreenModalIsActive,
  ] = React.useState(false);

  const currentPage = parseInt(page, 10) - 1 >= 0 ? parseInt(page, 10) - 1 : 0;
  let bookingStatus = null;
  let guestStatus = null;

  if (tab === 0) {
    bookingStatus = bookingStatusFilter || null;
    guestStatus = guestStatusFilter || null;
  } else if (tab === 1) {
    bookingStatus = bookingStatusFilter || 'RECEIVED';
    guestStatus = guestStatusFilter || null;
  } else if (tab === 2) {
    guestStatus = guestStatusFilter || 'UPCOMING';
    bookingStatus = bookingStatusFilter || null;
  } else if (tab === 3) {
    guestStatus = guestStatusFilter || 'PREVIOUS';
    bookingStatus = bookingStatusFilter || null;
  }

  const [chartDate, setChartDate] = React.useState(new Date());

  const [
    tableBookingLoading,
    tableBookings,
    pageCount,
    totalElements,
  ] = useTableBookingsData({
    adminUserId,
    placeId,
    dateFilter:
      tab === 0
        ? dateFilter
            .toLocaleDateString('en-GB', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            })
            .replaceAll('/', '-')
        : null,
    bookingStatus,
    guestStatus,
    tags,
    from: currentPage * 10,
  });

  const [
    tableBookingByTimeSlotsDataLoading,
    tableBookingByTimeSlotsData,
  ] = useTableBookingByTimeSlotsData({
    date: chartDate
      .toLocaleDateString('en-GB', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replaceAll('/', '-'),
    placeId,
    userId: adminUserId,
  });

  const tableBookingAggregates = tableBookings.reduce(
    (prev, current) => {
      const temp = { ...prev };
      if (current.guest_count) {
        temp.guest_count += current.guest_count;
      }
      if (current.booking_status === 'CANCELLED') {
        temp.cancelled += 1;
      }
      if (current.guest_status === 'SEATED') {
        temp.seated += 1;
      }
      return temp;
    },
    {
      guest_count: 0,
      cancelled: 0,
      seated: 0,
    },
  );

  console.log('totalElements', totalElements);

  return (
    <Layout>
      <Header history={history} placeId={placeId} />
      <TabSection
        tab={tab}
        setTab={v => {
          history.push(
            `/table-booking-manager/${placeId}${qs.stringify(
              {
                ...routeState,
                tab: v,
                page: 0,
              },
              true,
            )}`,
          );
        }}
      />
      <div style={{ position: 'relative' }}>
        <FilterSection
          tab={tab}
          onClickAddBooking={() =>
            history.push(`/add-modify-booking/${placeId}`)
          }
          tags={tags}
          bookingStatusFilter={bookingStatusFilter}
          guestStatusFilter={guestStatusFilter}
          openTagsFilterModal={() => setTagFilterModalActive(true)}
          openStatusFilterModal={() => setStatusFilterModalIsActive(true)}
          openChartsScreen={() => {
            setChartsScreenModalIsActive(true);
          }}
          dateFilter={dateFilter}
          setDateFilter={setDateFilter}
          disableDateSelector={!(tab === 0 || tab === 4)}
          clearStatusFilters={() => {
            history.push(
              `/table-booking-manager/${placeId}${qs.stringify(
                {
                  ...routeState,
                  guestStatusFilter: '',
                  bookingStatusFilter: '',
                },
                true,
              )}`,
            );
          }}
          clearTagsFilters={() => {
            history.push(
              `/table-booking-manager/${placeId}${qs.stringify(
                {
                  ...routeState,
                  tags: '',
                },
                true,
              )}`,
            );
          }}
        />
        {tab !== 4 && (
          <StatisticSection
            dateFilter={dateFilter}
            totalBookings={totalElements}
            totalGuestCount={tableBookingAggregates.guest_count}
            totalSeated={tableBookingAggregates.seated}
            totalCancelled={tableBookingAggregates.cancelled}
          />
        )}
        {tab !== 4 && totalElements > 0 && (
          <Pagination
            pageFrom={currentPage * 10}
            pageCount={pageCount}
            listSize={10}
            totalElements={totalElements}
            currentPage={currentPage}
            handlePageClick={value => {
              history.push(
                `/table-booking-manager/${placeId}${qs.stringify(
                  {
                    ...routeState,
                    page: value.selected + 1,
                  },
                  true,
                )}`,
              );
            }}
          />
        )}
        {tab === 4 ? (
          <BookingSessions
            dateFilter={dateFilter}
            placeId={placeId}
            userId={adminUserId}
            serviceTypeSettingId={tableSettingProps?.service_type_setting_id}
          />
        ) : (
          <BookingManagerTable
            sort={sort}
            setSort={setSort}
            tableBookingLoading={tableBookingLoading}
            tableBookings={tableBookings}
            placeId={placeId}
            history={history}
            tab={tab}
          />
        )}
      </div>
      {tagFilterModalActive && (
        <TagFilterModal
          tagsFromSetting={tableSettingProps.tag}
          initialTags={tags}
          isActiveModal={tagFilterModalActive}
          onClose={() => setTagFilterModalActive(false)}
          changeRoute={value => {
            history.push(
              `/table-booking-manager/${placeId}${qs.stringify(
                {
                  ...routeState,
                  tags: value,
                },
                true,
              )}`,
            );
          }}
        />
      )}

      {statusFilterModalIsActive && (
        <StatusFilterModal
          tab={tab}
          initialGuestStatusFilter={guestStatusFilter}
          initialBookingStatusFilter={bookingStatusFilter}
          isActiveModal={statusFilterModalIsActive}
          onClose={() => setStatusFilterModalIsActive(false)}
          changeRoute={values => {
            history.push(
              `/table-booking-manager/${placeId}${qs.stringify(
                {
                  ...routeState,
                  guestStatusFilter: values.guestStatusFilter,
                  bookingStatusFilter: values.bookingStatusFilter,
                },
                true,
              )}`,
            );
          }}
        />
      )}

      {chartsScreenModalIsActive && (
        <ChartsScreen
          chartDate={chartDate}
          setChartDate={setChartDate}
          isActiveModal={chartsScreenModalIsActive}
          onClose={() => {
            setChartsScreenModalIsActive(false);
            setChartDate(new Date());
          }}
          tableBookingByTimeSlotsDataLoading={
            tableBookingByTimeSlotsDataLoading
          }
          tableBookingByTimeSlotsData={tableBookingByTimeSlotsData}
        />
      )}
    </Layout>
  );
};

export default TableBookingManager;
