import React from 'react';
import styled from 'styled-components';

import { Label } from 'rbx';

import {
  CheckBox,
  // TextArea,
  Radio,
  InputErrorMessage,
  // InputErrorMessage,
  InputAddOn,
} from '../../../components/elements';
import Toggle from '../Menu/Designer/Toggle';

import BasicTextEditor from './BasicTextEditor';

const Info = styled.p`
  color: #b0b0b0;
  font-size: 12px;
`;

const PaddingDiv = styled.div`
  padding: ${props => props.verticalPadding || '1rem'}
    ${props => props.horizontalPadding || '0rem'};
`;

const PaymentPolicy = ({
  values,
  errors,
  touched,
  setFieldValue,

  // handleBlur,
  // handleChange,
  //   menuOptions,
  //   selectedMenuValue,
  //   onChangeMenu,
  //   place,
}) => (
  <div style={{ padding: '2rem' }}>
    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '140px' }}>Collect Booking Fee</div>
        <div style={{ position: 'relative', bottom: '2px' }}>
          <Toggle
            checked={values.collect_booking_fee}
            onToggle={value => {
              setFieldValue('collect_booking_fee', value);
            }}
            // disabled={values.status === 'INACTIVE'}
          />
        </div>
      </Label>
    </PaddingDiv>
    <div className="is-flex">
      <div style={{ paddingRight: '12px' }}>
        <Radio
          textBlack
          label="Flat Fee"
          value={values.booking_type === 'FLAT_FEE'}
          onChange={value => {
            if (value) {
              setFieldValue('booking_type', 'FLAT_FEE');
            } else {
              setFieldValue('booking_type', 'FEE_PER_GUEST');
            }
          }}
          disabled={!values.collect_booking_fee}
        />
      </div>

      <Radio
        textBlack
        label="Fee Per Guest"
        value={values.booking_type === 'FEE_PER_GUEST'}
        onChange={value => {
          if (value) {
            setFieldValue('booking_type', 'FEE_PER_GUEST');
          } else {
            setFieldValue('booking_type', 'FLAT_FEE');
          }
        }}
        disabled={!values.collect_booking_fee}
      />
    </div>
    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>Minimum Party Size</div>
        <input
          type="number"
          id="numberInput"
          name="numberInput"
          min="0"
          max="100"
          step="1"
          value={values.min_guest_count}
          onChange={e => setFieldValue('min_guest_count', e.target.value)}
          disabled={!values.collect_booking_fee}
        />
        &nbsp; &nbsp;
        <div>Guest(s)</div>
      </Label>
      <Info>Min Amount of guests required to collect payment.</Info>
      {errors.min_guest_count && (
        <p className="help is-danger is-size-4">{errors.min_guest_count}</p>
      )}
    </PaddingDiv>

    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>Booking / Deposit Fee</div>
        <InputAddOn
          addonsText="$"
          isLeft
          type="number"
          id="numberInput"
          name="numberInput"
          min="0"
          max="100"
          step="1"
          value={values.booking_deposit}
          onChange={e => setFieldValue('booking_deposit', e.target.value)}
          disabled={!values.collect_booking_fee}
        />
        &nbsp; &nbsp;
        <div>Per Guest</div>
      </Label>
      <Info>Fee collected per booking.</Info>
      {errors.booking_deposit && (
        <p className="help is-danger is-size-4">{errors.booking_deposit}</p>
      )}
    </PaddingDiv>

    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>
          Payment will be applied as deposit to the Bill
        </div>
        <CheckBox
          value={values.apply_deposit_to_bill}
          onChange={value => setFieldValue('apply_deposit_to_bill', value)}
          disabled={!values.collect_booking_fee}
        />
      </Label>
    </PaddingDiv>

    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>
          Collect booking fee on specific Days{' '}
        </div>
        <CheckBox
          value={values.booking_specific_days}
          onChange={value => setFieldValue('booking_specific_days', value)}
          disabled={!values.collect_booking_fee}
        />
      </Label>
    </PaddingDiv>

    <InputErrorMessage errors={errors.booking_days_list} touched />

    <div className="is-flex">
      <PaddingDiv>
        <Label className="is-flex">
          <CheckBox
            disabled={
              !values.collect_booking_fee || !values.booking_specific_days
            }
            value={
              !!(values.booking_days_list ?? []).find(ele => ele === 'SUN')
            }
            onChange={
              value => {
                if (value) {
                  setFieldValue('booking_days_list', [
                    ...values.booking_days_list,
                    'SUN',
                  ]);
                } else {
                  const temp = values.booking_days_list.filter(
                    e => e !== 'SUN',
                  );
                  setFieldValue('booking_days_list', temp);
                }
              }
              //   setFieldValue('schedule_order_when_closed', value)
            }
          />
          <div style={{ width: '70px' }}>S</div>
        </Label>
      </PaddingDiv>
      <PaddingDiv>
        <Label className="is-flex">
          <CheckBox
            disabled={
              !values.collect_booking_fee || !values.booking_specific_days
            }
            value={
              !!(values.booking_days_list ?? []).find(ele => ele === 'MON')
            }
            onChange={value => {
              if (value) {
                setFieldValue('booking_days_list', [
                  ...values.booking_days_list,
                  'MON',
                ]);
              } else {
                const temp = values.booking_days_list.filter(e => e !== 'MON');
                setFieldValue('booking_days_list', temp);
              }
            }}
          />
          <div style={{ width: '70px' }}>M</div>
        </Label>
      </PaddingDiv>
      <PaddingDiv>
        <Label className="is-flex">
          <CheckBox
            disabled={
              !values.collect_booking_fee || !values.booking_specific_days
            }
            value={
              !!(values.booking_days_list ?? []).find(ele => ele === 'TUE')
            }
            onChange={value => {
              if (value) {
                setFieldValue('booking_days_list', [
                  ...values.booking_days_list,
                  'TUE',
                ]);
              } else {
                const temp = values.booking_days_list.filter(e => e !== 'TUE');
                setFieldValue('booking_days_list', temp);
              }
            }}
          />
          <div style={{ width: '70px' }}>T</div>
        </Label>
      </PaddingDiv>
      <PaddingDiv>
        <Label className="is-flex">
          <CheckBox
            disabled={
              !values.collect_booking_fee || !values.booking_specific_days
            }
            value={
              !!(values.booking_days_list ?? []).find(ele => ele === 'WED')
            }
            onChange={value => {
              if (value) {
                setFieldValue('booking_days_list', [
                  ...values.booking_days_list,
                  'WED',
                ]);
              } else {
                const temp = values.booking_days_list.filter(e => e !== 'WED');
                setFieldValue('booking_days_list', temp);
              }
            }}
          />
          <div style={{ width: '70px' }}>W</div>
        </Label>
      </PaddingDiv>
      <PaddingDiv>
        <Label className="is-flex">
          <CheckBox
            disabled={
              !values.collect_booking_fee || !values.booking_specific_days
            }
            value={
              !!(values.booking_days_list ?? []).find(ele => ele === 'THU')
            }
            onChange={value => {
              if (value) {
                setFieldValue('booking_days_list', [
                  ...values.booking_days_list,
                  'THU',
                ]);
              } else {
                const temp = values.booking_days_list.filter(e => e !== 'THU');
                setFieldValue('booking_days_list', temp);
              }
            }}
          />
          <div style={{ width: '70px' }}>T</div>
        </Label>
      </PaddingDiv>
      <PaddingDiv>
        <Label className="is-flex">
          <CheckBox
            disabled={
              !values.collect_booking_fee || !values.booking_specific_days
            }
            value={
              !!(values.booking_days_list ?? []).find(ele => ele === 'FRI')
            }
            onChange={value => {
              if (value) {
                setFieldValue('booking_days_list', [
                  ...values.booking_days_list,
                  'FRI',
                ]);
              } else {
                const temp = values.booking_days_list.filter(e => e !== 'FRI');
                setFieldValue('booking_days_list', temp);
              }
            }}
          />
          <div style={{ width: '70px' }}>F</div>
        </Label>
      </PaddingDiv>
      <PaddingDiv>
        <Label className="is-flex">
          <CheckBox
            disabled={
              !values.collect_booking_fee || !values.booking_specific_days
            }
            value={
              !!(values.booking_days_list ?? []).find(ele => ele === 'SAT')
            }
            onChange={value => {
              if (value) {
                setFieldValue('booking_days_list', [
                  ...values.booking_days_list,
                  'SAT',
                ]);
              } else {
                const temp = values.booking_days_list.filter(e => e !== 'SAT');
                setFieldValue('booking_days_list', temp);
              }
            }}
          />
          <div style={{ width: '70px' }}>S</div>
        </Label>
      </PaddingDiv>
    </div>
    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>Grace Period</div>
        <input
          disabled={!values.collect_booking_fee}
          type="number"
          id="numberInput"
          name="numberInput"
          min="0"
          max="500"
          step="1"
          value={values.grace_period_minute}
          onChange={e => setFieldValue('grace_period_minute', e.target.value)}
        />
        &nbsp; &nbsp;
        <div>Minutes</div>
      </Label>
      {errors.grace_period_minute && (
        <p className="help is-danger is-size-4">{errors.grace_period_minute}</p>
      )}
    </PaddingDiv>

    <PaddingDiv>
      <Label className="is-flex">
        <div style={{ width: '250px' }}>Booking Period</div>
        <input
          disabled={!values.collect_booking_fee}
          type="number"
          id="numberInput"
          name="numberInput"
          min="0"
          max="500"
          step="1"
          value={values.booking_period_minute}
          onChange={e => setFieldValue('booking_period_minute', e.target.value)}
        />
        &nbsp; &nbsp;
        <div>Minutes</div>
      </Label>
      {errors.booking_period_minute && (
        <p className="help is-danger is-size-4">
          {errors.booking_period_minute}
        </p>
      )}
    </PaddingDiv>

    <br />

    <Label>Booking Terms: Terms & Conditions</Label>
    <BasicTextEditor
      disabled={!values.collect_booking_fee}
      name="booking_message"
      value={values.booking_terms_and_condition}
      handleChange={e => setFieldValue('booking_terms_and_condition', e)}
      // onBlur={handleBlur}
      errors={errors.booking_terms_and_condition}
      touched={touched.booking_terms_and_condition}
      disableToastMessage
      optionalText="(Min 10 and Max 915 characters)"
    />

    {values.booking_terms_and_condition && (
      <div style={{ textAlign: 'right' }}>
        {values.booking_terms_and_condition.length > 915 ||
        values.booking_terms_and_condition.length < 10
          ? ''
          : `Characters left ${915 -
              values.booking_terms_and_condition.length}`}
      </div>
    )}
  </div>
);

export default PaymentPolicy;
