/* eslint-disable max-len */
import React from 'react';
import * as yup from 'yup';
import { withFormik } from 'formik';

import uuidv4 from 'uuid/v4';

import { isNull } from 'lodash';

import swal from 'sweetalert';

import { toast } from 'react-toastify';

// import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { placeBusinessHoursHandler } from '../../../utils/helpers';

import { addIdInputHours } from '../Menu/helper';

import Layout from '../../../components/global/Layout';
import Header from './Header';

import { updateTableSetting } from './helpers';

import TabSection from './TabSection';

import Footer from './Footer';

import CancellationPolicy from './CancellationPolicy';
import PaymentPolicy from './PaymentPolicy';

const getBookingDaysList = booking => {
  if (Array.isArray(booking)) {
    return booking;
  }
  if (booking) {
    return [booking];
  }
  return [];
};

const Form = ({ ...props }) => {
  const [tab, setTab] = React.useState(0);

  const {
    // loading,
    // dirty,
    userId,
    values,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    errors,
    touched,
    menus,
    // setActiveMenu,
    place,
    history,
    noMenusAvailable,
  } = props;

  console.log('values', values);

  const onSubmit = event => {
    event.preventDefault();
    handleSubmit();
  };

  console.log('userId', userId);

  return (
    <Layout>
      <form onSubmit={onSubmit}>
        <Header
          history={history}
          loading={isSubmitting}
          showSubmit
          place={place}
        />
        <TabSection setTab={setTab} tab={tab} />

        {tab === 0 && (
          <PaymentPolicy
            place={place}
            menuOptions={
              noMenusAvailable
                ? []
                : menus.map(ele => ({
                    value: ele,
                    label: ele.name,
                  }))
            }
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            selectedMenuValue={{
              value: values?.selectedMenu,
              label: values?.selectedMenu?.name,
            }}
            onChangeMenu={mnu => {
              placeBusinessHoursHandler(addIdInputHours(mnu.menu_hour));
            }}
          />
        )}
        {tab === 1 && (
          <CancellationPolicy
            place={place}
            menuOptions={
              noMenusAvailable
                ? []
                : menus.map(ele => ({
                    value: ele,
                    label: ele.name,
                  }))
            }
            values={values}
            errors={errors}
            touched={touched}
            handleChange={handleChange}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
            selectedMenuValue={{
              value: values?.selectedMenu,
              label: values?.selectedMenu?.name,
            }}
            onChangeMenu={mnu => {
              placeBusinessHoursHandler(addIdInputHours(mnu.menu_hour));
            }}
          />
        )}
        <Footer history={history} showSubmit loading={isSubmitting} />
      </form>
    </Layout>
  );
};

const TableBookingSettingForm = withFormik({
  mapPropsToValues: ({ tableBookingSetting }) => ({
    service_type: 'Table booking',

    cancel_booking:
      tableBookingSetting &&
      tableBookingSetting.cancel_booking &&
      !isNull(tableBookingSetting.cancel_booking?.is_cancel_booking)
        ? tableBookingSetting.cancel_booking.is_cancel_booking
        : false,

    modify_booking:
      tableBookingSetting && !isNull(tableBookingSetting?.modify_booking)
        ? tableBookingSetting.modify_booking
        : false,

    no_show_refund:
      tableBookingSetting &&
      tableBookingSetting?.no_show_refund?.no_show_refund_value &&
      !isNull(tableBookingSetting.no_show_refund.no_show_refund_value)
        ? tableBookingSetting.no_show_refund.no_show_refund_value
        : 'FULL',

    no_show_fee_refund:
      tableBookingSetting &&
      tableBookingSetting?.no_show_refund?.no_show_fee_refund &&
      !isNull(tableBookingSetting.no_show_refund.no_show_fee_refund)
        ? tableBookingSetting.no_show_refund.no_show_fee_refund
        : '',

    cut_off_time_in_hours:
      tableBookingSetting &&
      tableBookingSetting?.cancel_booking?.cancel_booking_info
        ?.cut_off_time_in_hours &&
      !isNull(
        tableBookingSetting.cancel_booking.cancel_booking_info
          .cut_off_time_in_hours,
      )
        ? tableBookingSetting.cancel_booking.cancel_booking_info
            .cut_off_time_in_hours
        : 72,

    refund_before_cut_off_time:
      tableBookingSetting &&
      tableBookingSetting?.cancel_booking?.cancel_booking_info
        ?.refund_before_cut_off_time &&
      !isNull(
        tableBookingSetting.cancel_booking.cancel_booking_info
          .refund_before_cut_off_time,
      )
        ? tableBookingSetting.cancel_booking.cancel_booking_info
            .refund_before_cut_off_time
        : 'FULL',

    refund_fee_before_cut_off:
      tableBookingSetting &&
      tableBookingSetting?.cancel_booking?.cancel_booking_info
        ?.refund_fee_before_cut_off &&
      !isNull(
        tableBookingSetting.cancel_booking.cancel_booking_info
          .refund_fee_before_cut_off,
      )
        ? tableBookingSetting.cancel_booking.cancel_booking_info
            .refund_fee_before_cut_off
        : '',

    refund_after_cut_off_time:
      tableBookingSetting &&
      tableBookingSetting?.cancel_booking?.cancel_booking_info
        ?.refund_after_cut_off_time &&
      !isNull(
        tableBookingSetting.cancel_booking.cancel_booking_info
          .refund_after_cut_off_time,
      )
        ? tableBookingSetting.cancel_booking.cancel_booking_info
            .refund_after_cut_off_time
        : 'FULL',

    refund_fee_after_cut_off:
      tableBookingSetting &&
      tableBookingSetting?.cancel_booking?.cancel_booking_info
        ?.refund_fee_after_cut_off &&
      !isNull(
        tableBookingSetting.cancel_booking.cancel_booking_info
          .refund_fee_after_cut_off,
      )
        ? tableBookingSetting.cancel_booking.cancel_booking_info
            .refund_fee_after_cut_off
        : '',

    collect_booking_fee:
      tableBookingSetting &&
      tableBookingSetting?.booking_fee &&
      !isNull(tableBookingSetting.booking_fee?.collect_booking_fee)
        ? tableBookingSetting.booking_fee.collect_booking_fee
        : false,

    apply_deposit_to_bill:
      tableBookingSetting &&
      tableBookingSetting?.booking_fee?.booking_fee_info &&
      !isNull(
        tableBookingSetting.booking_fee.booking_fee_info.apply_deposit_to_bill,
      )
        ? tableBookingSetting.booking_fee.booking_fee_info.apply_deposit_to_bill
        : false,

    booking_deposit:
      tableBookingSetting &&
      tableBookingSetting?.booking_fee?.booking_fee_info?.booking_deposit &&
      !isNull(tableBookingSetting.booking_fee.booking_fee_info.booking_deposit)
        ? tableBookingSetting.booking_fee.booking_fee_info.booking_deposit
        : '',

    booking_period_minute:
      tableBookingSetting &&
      tableBookingSetting?.booking_fee?.booking_fee_info
        ?.booking_period_minute &&
      !isNull(
        tableBookingSetting.booking_fee.booking_fee_info.booking_period_minute,
      )
        ? tableBookingSetting.booking_fee.booking_fee_info.booking_period_minute
        : 90,

    booking_specific_days:
      tableBookingSetting &&
      tableBookingSetting?.booking_fee?.booking_fee_info &&
      !isNull(
        tableBookingSetting.booking_fee.booking_fee_info?.booking_specific_days,
      )
        ? tableBookingSetting.booking_fee.booking_fee_info.booking_specific_days
        : false,

    booking_type:
      tableBookingSetting &&
      tableBookingSetting?.booking_fee?.booking_fee_info?.booking_type &&
      !isNull(tableBookingSetting.booking_fee.booking_fee_info.booking_type)
        ? tableBookingSetting.booking_fee.booking_fee_info.booking_type
        : 'FEE_PER_GUEST',

    grace_period_minute:
      tableBookingSetting &&
      tableBookingSetting?.booking_fee?.booking_fee_info?.grace_period_minute &&
      !isNull(
        tableBookingSetting.booking_fee.booking_fee_info.grace_period_minute,
      )
        ? tableBookingSetting.booking_fee.booking_fee_info.grace_period_minute
        : 15,

    min_guest_count:
      tableBookingSetting &&
      tableBookingSetting?.booking_fee?.booking_fee_info?.min_guest_count &&
      !isNull(tableBookingSetting.booking_fee.booking_fee_info.min_guest_count)
        ? tableBookingSetting.booking_fee.booking_fee_info.min_guest_count
        : 2,

    booking_days_list:
      tableBookingSetting &&
      tableBookingSetting?.booking_fee?.booking_fee_info?.booking_days_list &&
      !isNull(
        tableBookingSetting.booking_fee.booking_fee_info.booking_days_list,
      )
        ? getBookingDaysList(
            tableBookingSetting.booking_fee.booking_fee_info.booking_days_list,
          )
        : [],

    service_type_setting_id:
      tableBookingSetting &&
      !isNull(tableBookingSetting.service_type_setting_id)
        ? tableBookingSetting.service_type_setting_id
        : uuidv4(),

    status:
      tableBookingSetting && !isNull(tableBookingSetting.status)
        ? tableBookingSetting.status
        : 'ACTIVE',

    publish_booking:
      tableBookingSetting && !isNull(tableBookingSetting.publish_booking)
        ? tableBookingSetting.publish_booking
        : true,
    booking_terms_and_condition:
      tableBookingSetting &&
      !isNull(tableBookingSetting.booking_terms_and_condition)
        ? tableBookingSetting.booking_terms_and_condition
        : `
        1. As a courtesy you agree to contact the restaurant if you have any changes, updates or need to cancel your booking.
        2. Your booking will be allocated to the best available table.
        3. Any special requests made will be catered for by the venue as best as possible, please contact the venue directly if you require confirmation of your request.
        4. Your information will be used in accordance with the KRAVEiN Privacy Policy
        https://www.kravein.com.au/privacy-policy
        
        We have a 5 minute grace period. Please call us if you are running later than 5 minutes after your reservation time.We may contact you about this reservation, so please ensure your email and phone number are up to date
        `,
    cancellation_terms_and_condition:
      tableBookingSetting &&
      !isNull(tableBookingSetting.cancellation_terms_and_condition)
        ? tableBookingSetting.cancellation_terms_and_condition
        : `
        1. The processing  fees are not refundable.
        2. The booking fee Any cancellation before 72 hours will be eligible for a full refund
        3. Cancellation within 72 hours will  be fully refunded
        4. Cancellation within 24 hours will  be charged $20 per user
        5. No refund will be processed for No Shows
        6. Modifications to your booking are allowed any time provided the slots are available.
        
        We may contact you about this reservation, so please ensure your email and phone number are up to date.
        `,
  }),
  validationSchema: yup.object().shape({
    collect_booking_fee: yup.boolean(),

    cancel_booking: yup.boolean(),

    booking_deposit: yup.number().when('collect_booking_fee', {
      is: true,
      then: yup
        .number()
        .transform((value, originalValue) => (!originalValue ? 0 : value))
        .required('Booking / Deposit is required')
        .min(1, 'Booking / Deposit must be greater than or equal to 1')
        .max(100, 'Booking / Deposit must be less than or equal to 100'),
      otherwise: yup.number(),
    }),

    booking_period_minute: yup.number().when('collect_booking_fee', {
      is: true,
      then: yup
        .number()
        .transform((value, originalValue) => (!originalValue ? 0 : value))
        .required('Booking period minute is required')
        .integer('Booking period minute must be a valid integer')
        .min(1, 'Booking period minute must be greater than or equal to 1')
        .max(500, 'Booking period minute must be less than or equal to 500'),
      otherwise: yup.number(),
    }),

    grace_period_minute: yup.number().when('collect_booking_fee', {
      is: true,
      then: yup
        .number()
        .transform((value, originalValue) => (!originalValue ? 0 : value))
        .required('Grace Period is required')
        .integer('Grace Period must be a valid integer')
        .min(1, 'Grace Period must be greater than or equal to 1')
        .max(500, 'Grace Period must be less than or equal to 500'),
      otherwise: yup.number(),
    }),

    min_guest_count: yup.number().when('collect_booking_fee', {
      is: true,
      then: yup
        .number()
        .transform((value, originalValue) => (!originalValue ? 0 : value))
        .required('Minimum Guest Count is required')
        .integer('Minimum Guest Count must be a valid integer')
        .min(1, 'Minimum Guest Count must be greater than or equal to 1')
        .max(100, 'Minimum Guest Count must be less than or equal to 100'),
      otherwise: yup.number(),
    }),

    cut_off_time_in_hours: yup.number().when('cancel_booking', {
      is: true,
      then: yup
        .number()
        .transform((value, originalValue) => (!originalValue ? 0 : value))
        .required('Cut off time is required')
        .integer('Cut off time must be a valid integer')
        .min(1, 'Cut off time must be greater than or equal to 1')
        .max(100, 'Cut off time must be less than or equal to 100'),
      otherwise: yup.number(),
    }),

    refund_after_cut_off_time: yup.string(),

    refund_before_cut_off_time: yup.string(),

    no_show_refund: yup.string(),

    refund_fee_after_cut_off: yup
      .number()
      .when(['refund_after_cut_off_time', 'cancel_booking'], {
        is: (refund_after_cut_off_time, cancel_booking) =>
          cancel_booking === true && refund_after_cut_off_time === 'PARTIAL',
        then: yup
          .number()
          .transform((value, originalValue) => (!originalValue ? 0 : value))
          .required('Refund fee after cut off is required')
          .integer('Refund fee after cut off must be a valid integer')
          .min(1, 'Refund fee after cut off must be greater than or equal to 1')
          .max(
            100,
            'Refund fee after cut off must be less than or equal to 100',
          ),
        otherwise: yup.number(),
      }),

    refund_fee_before_cut_off: yup
      .number()
      .when(['refund_before_cut_off_time', 'cancel_booking'], {
        is: (refund_before_cut_off_time, cancel_booking) =>
          cancel_booking === true && refund_before_cut_off_time === 'PARTIAL',
        then: yup
          .number()
          .transform((value, originalValue) => (!originalValue ? 0 : value))
          .required('Refund fee before cut off is required')
          .integer('Refund fee before cut off must be a valid integer')
          .min(
            1,
            'Refund fee before cut off must be greater than or equal to 1',
          )
          .max(
            100,
            'Refund fee before cut off must be less than or equal to 100',
          ),
        otherwise: yup.number(),
      }),

    no_show_fee_refund: yup
      .number()
      .when(['no_show_refund', 'cancel_booking'], {
        is: (no_show_refund, cancel_booking) =>
          cancel_booking === true && no_show_refund === 'PARTIAL',
        then: yup
          .number()
          .transform((value, originalValue) => (!originalValue ? 0 : value))
          .required('No show fee refund is required')
          .integer('No show fee refund must be a valid integer')
          .min(1, 'No show fee refund must be greater than or equal to 1')
          .max(100, 'No show fee refund must be less than or equal to 100'),
        otherwise: yup.number(),
      }),

    booking_specific_days: yup.boolean(),

    booking_days_list: yup
      .array()
      .when(['collect_booking_fee', 'booking_specific_days'], {
        is: (collect_booking_fee, booking_specific_days) =>
          collect_booking_fee === true && booking_specific_days === true,
        then: yup.array().min(1, 'Please select atleast one day'),
        otherwise: yup.array(),
      }),

    booking_terms_and_condition: yup
      .string()
      .min(
        10,
        'Booking terms and condition must be greater than or equal to 10',
      )
      .max(
        915,
        'Booking terms and condition must be less than or equal to 915',
      ),

    cancellation_terms_and_condition: yup
      .string()
      .min(
        10,
        'Cancellation terms and condition must be greater than or equal to 10',
      )
      .max(
        772,
        'Cancellation terms and condition must be less than or equal to 772',
      ),
  }),

  validateOnBlur: false,
  validateOnChange: false,
  handleSubmit: async (values, { props, setSubmitting }) => {
    setSubmitting(true);
    const {
      apply_deposit_to_bill,
      booking_deposit,
      booking_period_minute,
      booking_type,
      grace_period_minute,
      min_guest_count,
      collect_booking_fee,
      cancel_booking,
      // modify_booking,
      no_show_refund,
      no_show_fee_refund,
      cut_off_time_in_hours,
      refund_before_cut_off_time,
      refund_fee_before_cut_off,
      refund_after_cut_off_time,
      refund_fee_after_cut_off,
      publish_booking,
      service_type_setting_id,
      service_type,
      booking_terms_and_condition,
      booking_specific_days,
      cancellation_terms_and_condition,
    } = values;

    let { booking_days_list } = values;

    if (!booking_specific_days) {
      booking_days_list = [];
    }

    const input = {
      user_id: props.userId,
      status: publish_booking ? 'ACTIVE' : 'INACTIVE',
      publish_booking,
      place_id: props.place.place_id,
      service_type_setting_id,
      service_type,
      booking_terms_and_condition,
      cancellation_terms_and_condition,
    };

    input.no_show_refund = {
      no_show_refund_value: no_show_refund,
      no_show_fee_refund: no_show_fee_refund === '' ? 0 : no_show_fee_refund,
    };

    if (collect_booking_fee && cancel_booking) {
      const threshold = Math.round(booking_deposit * 0.95);
      if (refund_fee_before_cut_off > threshold) {
        toast.error('Exceeded Threshold');
        return;
      }

      if (refund_fee_after_cut_off > threshold) {
        toast.error('Exceeded Threshold');
        return;
      }
    }

    if (cancel_booking) {
      input.cancel_booking = {
        is_cancel_booking: true,
        cancel_booking_info: {
          cut_off_time_in_hours:
            cut_off_time_in_hours === '' ? 0 : cut_off_time_in_hours,
          refund_before_cut_off_time,
          refund_fee_before_cut_off:
            refund_fee_before_cut_off === '' ? 0 : refund_fee_before_cut_off,
          refund_after_cut_off_time,
          refund_fee_after_cut_off:
            refund_fee_after_cut_off === '' ? 0 : refund_fee_after_cut_off,
        },
      };
    } else {
      input.cancel_booking = {
        is_cancel_booking: false,
        // cancel_booking_info: {
        //   cut_off_time_in_hours: null,
        //   refund_before_cut_off_time: null,
        //   refund_fee_before_cut_off: null,
        //   refund_after_cut_off_time: null,
        //   refund_fee_after_cut_off: null,
        // },
      };
    }

    if (collect_booking_fee) {
      if (booking_days_list.length !== 0) {
        input.booking_fee = {
          collect_booking_fee: true,
          booking_fee_info: {
            apply_deposit_to_bill,
            booking_deposit: booking_deposit === '' ? 0 : booking_deposit,
            booking_period_minute:
              booking_period_minute === '' ? 0 : booking_period_minute,
            booking_specific_days: true,
            booking_type,
            grace_period_minute:
              grace_period_minute === '' ? 0 : grace_period_minute,
            min_guest_count: min_guest_count === '' ? 0 : min_guest_count,
            booking_days_list,
          },
        };
      } else {
        input.booking_fee = {
          collect_booking_fee: true,
          booking_fee_info: {
            apply_deposit_to_bill,
            booking_deposit: booking_deposit === '' ? 0 : booking_deposit,
            booking_period_minute:
              booking_period_minute === '' ? 0 : booking_period_minute,
            booking_specific_days,
            booking_type,
            grace_period_minute:
              grace_period_minute === '' ? 0 : grace_period_minute,
            min_guest_count: min_guest_count === '' ? 0 : min_guest_count,
            booking_days_list,
          },
        };
      }
    } else {
      input.booking_fee = {
        collect_booking_fee: false,
        // booking_fee_info: {
        //   apply_deposit_to_bill: null,
        //   booking_deposit: null,
        //   booking_period_minute: null,
        //   booking_specific_days: null,
        //   booking_type: null,
        //   grace_period_minute: null,
        //   min_guest_count: null,
        //   booking_days_list: null,
        // },
      };
    }

    if (props.tableBookingSetting) {
      console.log('input...', input);
      const res = await updateTableSetting({ input });
      if (res === true) {
        swal('Successfully updated table booking setting...').then(() => {
          setSubmitting(false);
          props.history.push(`/table-booking-policy-listing`);
        });
      } else if (res === null) {
        swal({
          title: 'Error!',
          text: 'An unexpected error occurred. Please try again.',
          type: 'error',
          confirmButtonText: 'Okay',
        }).then(() => {
          setSubmitting(false);
        });
      } else {
        toast.error(res, {
          onClose: () => {
            setSubmitting(false);
          },
        });
      }
    } else {
      swal({
        title: 'Error!',
        text: 'An unexpected error occurred. Please try again.',
        type: 'error',
        confirmButtonText: 'Okay',
      }).then(() => {
        setSubmitting(false);
      });
    }
  },
  displayName: 'Form',
})(Form);

export default TableBookingSettingForm;
