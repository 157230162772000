import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import swal from 'sweetalert';
import { isNull, omit, isEqual, lowerCase } from 'lodash';
import { toast } from 'react-toastify';
import { useStoreState } from 'easy-peasy';

import client from '../../../utils/apolloClient';
import { ErrorMessage, Loading } from '../../../components/elements';
import Layout from '../../../components/global/Layout';
import {
  reverseDayEstimateValueParser,
  getServiceTypeSettingOpenClose,
  updateServiceTypeOpenClose,
} from './helpers';

import config from '../../../utils/config';

import Form from './Form';
import dirtyFieldHandler from './dirtyFieldHandler';

const placeQuery = gql`
  query place($input: SearchInput) {
    search_places(input: $input) {
      place_listing {
        place_id
        display_order
        name
        address_line_1
        address_line_2
        city
        state
        country
        post_code
        slug
        status
        claim_status
        latitude
        longitude
        service_type {
          description
          name
          display_order
        }
      }
    }
  }
`;

const settingQuery = gql`
  query setting($input: NavigateServiceTypeSettingInput) {
    fetch_service_type_setting(input: $input) {
      is_catering_service
      service_type_setting_id
      default_menu
      auto_cancel {
        is_auto_cancel
        time_in_mins
      }
      addition_emails
      description_enable
      delivery_integration {
        order_settings {
          customer_email
          delivery_details
          delivery_fees
          order_items
          order_number
          payment_details
          pickup_details
          total
        }
        api_key
        menu_id
        name
      }
      platform
      channel_link_id
      not_allow_order_to_create_on_deliverect
      auto_confirm
      auto_ready
      allow_order_cancellation
      delivery_channel
      place_id
      service_type
      status
      is_custom_service
      description
      tag
      url {
        uri
        display_order
      }
      is_private_menu
      image_url
      approval_status
      display_attendee_options
      display_table_number
      display_price
      display_menu_hours
      allow_pickup_options
      include_vehicle_information
      pickup_method
      pickup_instructions
      zone_setting {
        id
        name
        color_code
        delivery_area {
          area_type
          area_circle {
            location {
              latitude
              longitude
            }
            radius
          }
          area_polygon {
            latitude
            longitude
          }
        }
        delivery_fee
        delivery_estimate
      }
      day_estimate {
        day
        start_hour
        end_hour
        preparation_estimate
        delivery_estimate
      }
      contact {
        type
        value
        display
        is_primary
      }
      delivery_fee
      preparation_estimate
      delivery_estimate
      added_estimate
      cut_off_time
      food_setup_required
      minimum_no_of_attendees
      maximum_no_of_attendees
      minimum_order_amount
      maximum_order_amount
      payment_method
      enable_group_order
      schedule_order
      schedule_order_duration
      schedule_order_when_closed
      image_url
    }
  }
`;

const updateServiceTypeSettingMutation = gql`
  mutation updateServiceTypeSetting($input: ServiceTypeSettingInput) {
    update_service_type_setting(input: $input) {
      service_type_setting_id
      error {
        description
      }
    }
  }
`;

const computeOpenClose = (setting, serviceTypeOpenCloseData) => {
  if (setting && !isNull(serviceTypeOpenCloseData)) {
    switch (setting.service_type) {
      case 'Pickup':
        return {
          service_type: 'Pickup',
          status: serviceTypeOpenCloseData.hours_pickup,
          touched: false,
        };
      case 'Dinein':
        return {
          service_type: 'Dinein',
          status: serviceTypeOpenCloseData.hours_dinein,
          touched: false,
        };
      case 'Delivery':
        return {
          service_type: 'Delivery',
          status: serviceTypeOpenCloseData.hours_delivery,
          touched: false,
        };
      default:
        return null;
    }
  }
  return null;
};

const MenuTypeUpdate = ({ history, match }) => {
  const { userId } = useStoreState(state => state.auth);
  const { placeId, id } = match.params;
  const [updateAction, setUpdateAction] = React.useState(null);

  const [
    serviceTypeOpenCloseData,
    setServiceTypeOpenCloseData,
  ] = React.useState(null);

  useEffect(() => {
    getServiceTypeSettingOpenClose(id).then(data =>
      setServiceTypeOpenCloseData(data.fetch_service_type_setting_open_close),
    );
  }, []);

  return (
    <Layout>
      <Query
        query={placeQuery}
        client={client.clientPrivate}
        variables={{
          input: {
            filter: {
              place_filter: {
                place_id: placeId,
              },
            },
            user_id: userId,
          },
        }}
      >
        {({ data: placeData, loading: placeLoading, error: placeError }) => {
          if (placeLoading) {
            return <Loading />;
          }
          if (placeError) {
            return <ErrorMessage message={placeError.message} />;
          }

          const [place] = placeData.search_places.place_listing;
          return (
            <Query
              client={client.clientPrivate}
              query={settingQuery}
              variables={{
                input: {
                  service_type_setting_id: id,
                },
              }}
            >
              {({ data, loading, error }) => {
                if (loading) {
                  return <Loading />;
                }
                if (error) {
                  return <ErrorMessage message={error.message} />;
                }

                const setting = data.fetch_service_type_setting;
                return (
                  <Mutation
                    client={client.clientPrivate}
                    mutation={updateServiceTypeSettingMutation}
                    onCompleted={({ update_service_type_setting }, values) => {
                      if (!isNull(update_service_type_setting.error)) {
                        update_service_type_setting.error.map(item =>
                          toast.error(item.description),
                        );
                      } else if (!updateAction) {
                        swal(
                          'Great!',
                          'Service settings configured successfully!',
                          'success',
                        ).then(() => {
                          const { service_type } = setting;
                          const { slug } = place;
                          const pathname = `/order-food/${service_type}/${slug}`;
                          const basePath =
                            config.apiPrefix === 'dev1.'
                              ? 'https://dev11.portal.kravein.com.au'
                              : 'https://www.kravein.com.au';
                          try {
                            fetch(
                              `${basePath}/api/revalidate?url=${basePath}${pathname}`,
                            );
                          } catch {
                            // pass
                          }
                          history.goBack();
                        });
                      }
                    }}
                  >
                    {(
                      update_service_type_setting,
                      { loading: updateLoading, error: updateError },
                    ) => (
                      <>
                        {updateError && (
                          <ErrorMessage message={updateError.message} />
                        )}
                        <Form
                          place={place}
                          userId={userId}
                          serviceTypeSettingId={id}
                          openClose={computeOpenClose(
                            setting,
                            serviceTypeOpenCloseData,
                          )}
                          step={2}
                          setting={setting}
                          loading={updateLoading}
                          serviceTypes={[]}
                          onDeleteImage={() => {
                            setUpdateAction('remove_url');
                            update_service_type_setting({
                              variables: {
                                input: {
                                  image_url: null,
                                  user_id: userId,
                                  service_type_setting_id:
                                    setting.service_type_setting_id,
                                  place_id: setting.place_id,
                                },
                              },
                            });
                          }}
                          onSubmit={inputs => {
                            if (inputs.openClose && inputs.openClose.touched) {
                              updateServiceTypeOpenClose(
                                setting.service_type_setting_id,
                                inputs.openClose.status,
                              );
                            }
                            const input = omit(inputs, [
                              'platform',
                              'channel_link_id',
                              'not_allow_order_to_create_on_deliverect',
                              'menus',
                              'step',
                              'day_estimate',
                              'primaryEmail',
                              'primaryMobile',
                              'status',
                              'inStore',
                              'curbSide',
                              'carPark',
                              'driveThru',
                              'random_id',
                              'isAdd',
                            ]);
                            const dayEstimate = reverseDayEstimateValueParser(
                              inputs.day_estimate,
                            );
                            const primaryContacts = [
                              {
                                type: 'email_primary',
                                value: inputs.primaryEmail,
                                display: true,
                                is_primary: true,
                              },
                              {
                                type: 'phone_primary',
                                value: inputs.primaryMobile,
                                display: true,
                                is_primary: true,
                              },
                            ];
                            const finalInput = {
                              ...input,
                              day_estimate: dayEstimate,
                              contact: primaryContacts,
                            };
                            Object.assign(
                              finalInput,
                              inputs.status !== setting.status && {
                                status: inputs.status,
                              },
                            );
                            const dataF = dirtyFieldHandler(
                              finalInput,
                              setting,
                            );
                            if (
                              !setting.is_custom_service &&
                              !setting.display_menu_hours
                            ) {
                              Object.assign(dataF, {
                                display_menu_hours: true,
                              });
                            } else if (isNull(setting.display_menu_hours)) {
                              Object.assign(dataF, {
                                display_menu_hours: false,
                              });
                            } else if (
                              !isEqual(
                                setting.display_menu_hours,
                                input.display_menu_hours,
                              )
                            ) {
                              Object.assign(dataF, {
                                display_menu_hours: input.display_menu_hours,
                              });
                            }

                            if (
                              !setting.is_custom_service &&
                              !setting.display_price
                            ) {
                              Object.assign(dataF, {
                                display_price: true,
                              });
                            } else if (isNull(setting.display_price)) {
                              Object.assign(dataF, {
                                display_price: false,
                              });
                            } else if (
                              !isEqual(
                                setting.display_menu_hours,
                                input.display_menu_hours,
                              )
                            ) {
                              Object.assign(dataF, {
                                display_price: input.display_price,
                              });
                            }
                            if (!input.is_custom_service) {
                              Object.assign(
                                dataF,
                                {
                                  auto_confirm: input.auto_confirm,
                                },
                                { auto_ready: input.auto_ready },
                                {
                                  auto_cancel: {
                                    is_auto_cancel: input.is_auto_cancel,
                                    time_in_mins: input.time_in_mins,
                                  },
                                },
                                lowerCase(input.service_type) ===
                                  'delivery' && {
                                  delivery_channel: input.delivery_channel,
                                },
                                lowerCase(input.service_type) === 'delivery' &&
                                  input.delivery_integration.name ===
                                    'SHIPDAY' && {
                                    delivery_integration:
                                      input.delivery_integration,
                                  },
                              );
                            }
                            if (
                              lowerCase(input.service_type) === 'delivery' &&
                              setting?.delivery_integration?.name ===
                                'SHIPDAY' &&
                              input?.delivery_integration?.name !== 'SHIPDAY'
                            ) {
                              Object.assign(dataF, {
                                delivery_integration: {
                                  order_settings: {
                                    customer_email: false,
                                    delivery_details: true,
                                    delivery_fees: false,
                                    order_items: false,
                                    order_number: true,
                                    payment_details: false,
                                    pickup_details: true,
                                    total: false,
                                  },
                                  api_key: '',
                                  menu_id: [],
                                  name: '',
                                },
                              });
                            }
                            setUpdateAction(null);
                            update_service_type_setting({
                              variables: {
                                input: {
                                  ...dataF,
                                  allow_order_cancellation:
                                    input.allow_order_cancellation,
                                },
                              },
                            });
                          }}
                        />
                      </>
                    )}
                  </Mutation>
                );
              }}
            </Query>
          );
        }}
      </Query>
    </Layout>
  );
};

export default MenuTypeUpdate;
