import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
// import qs from 'querystringify';
// import { omit } from 'lodash';

import {
  Input,
  ReactDateTimePicker,
  // CheckBox,
  Select,
} from '../../../components/elements';
// import LocationSuggestion from '../../../components/global/LocationSuggestion';

const Wrapper = styled.nav`
  padding: 1rem;
  border-bottom: 1px solid #f5f8fa;
  padding-bottom: 1rem;
`;

const BoxWrapper = styled.nav`
  &&& {
    background: #f6f6f6;
  }
`;

// const options = [
//   { value: 'card', label: 'Card' },
//   { value: 'cash', label: 'Cash' },
// ];

const setDateHours = dateObject => {
  dateObject.setHours(0, 0, 0, 0);
  return dateObject;
};

const startDateISOString = dateObject => setDateHours(dateObject).toISOString();

const PlaceSearch = ({
  phone,
  setPhone,
  placename,
  username,
  useremail,
  paymentMethod,
  orderRef,
  status,
  service,
  onPlacenameChange,
  onUsernameChange,
  onUseremailChange,
  onPaymentMethodChange,
  onOrderRefChange,
  onStatusChange,
  onServiceChange,
  disabledForActiveTab1,
  disabledForActiveTab2,
  selectionKey,
  endDate,
  setEndDate,
  startDate,
  setStartDate,
  // setDateTimeActive,
  // dateTimeActive,
}) => (
  // const setDateHours = dateObject => {
  //   dateObject.setHours(0, 0, 0, 0);
  //   return dateObject;
  // };

  // const startDateISOString = dateObject =>
  //   setDateHours(dateObject).toISOString();

  <Wrapper>
    <BoxWrapper className="box">
      <div className="columns">
        <div className="column is-2">
          <Input
            label="Place Name"
            type="text"
            value={placename}
            placeholder=""
            onChange={event => onPlacenameChange(event.target.value)}
            // disabled={disabledForActiveTab1}
          />
        </div>
        <div className="column is-2">
          <Input
            label="User Display Name"
            type="text"
            value={username}
            placeholder=""
            onChange={event => onUsernameChange(event.target.value)}
            disabled={disabledForActiveTab2}
          />
        </div>
        <div className="column is-3">
          <Input
            label="User Email"
            type="text"
            value={useremail}
            placeholder=""
            onChange={event => onUseremailChange(event.target.value)}
            disabled={disabledForActiveTab2}
          />
        </div>
        <div className="column is-3">
          <Input
            label="User Phone"
            type="text"
            value={phone}
            onChange={event => setPhone(event.target.value)}
            disabled={disabledForActiveTab2}
          />
        </div>
        <div className="column is-2">
          <Select
            label="Payment Method"
            options={[
              { label: 'ALL', value: 'All' },
              { label: 'CASH', value: 'CASH' },
              { label: 'CARD', value: 'CARD' },
            ]}
            value={paymentMethod}
            onChange={value => onPaymentMethodChange(value)}
            // disabled={fee}
            disableToastMessage
            // disabled={disabledForActiveTab1}
            key={selectionKey}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column is-2">
          <Input
            label="Order Reference"
            type="text"
            value={orderRef}
            placeholder=""
            onChange={event => onOrderRefChange(event.target.value)}
            disabled={disabledForActiveTab1}
          />
        </div>
        <div className="column is-2">
          <Select
            label="Status"
            options={[
              { label: 'ALL', value: 'All' },
              { label: 'RECEIVED', value: 'RECEIVED' },
              { label: 'CONFIRMED', value: 'CONFIRMED' },
              { label: 'READY', value: 'READY' },
              { label: 'COMPLETE', value: 'COMPLETE' },
              { label: 'CANCELLED', value: 'CANCELLED' },
            ]}
            value={status}
            onChange={value => onStatusChange(value)}
            // disabled={fee}
            disableToastMessage
            // disabled={disabledForActiveTab1}
            key={selectionKey}
          />
        </div>
        <div className="column is-2">
          <Select
            label="Service"
            options={[
              { label: 'ALL', value: 'All' },
              { label: 'PICKUP', value: 'Pickup' },
              { label: 'DELIVERY', value: 'Delivery' },
              { label: 'DINEIN', value: 'Dinein' },
            ]}
            value={service}
            onChange={value => onServiceChange(value)}
            // disabled={fee}
            disableToastMessage
            // disabled={disabledForActiveTab1}
            key={selectionKey}
          />
        </div>
        <div className="column is-3">
          <ReactDateTimePicker
            // disabled={disabledForActiveTab1}
            value={startDate}
            label="From Date"
            onChange={date => {
              setStartDate(startDateISOString(new Date(date)));
            }}
            key={selectionKey}
            clear={false}
          />
        </div>
        <div className="column is-3">
          <ReactDateTimePicker
            key={selectionKey}
            // disabled={disabledForActiveTab1}
            minDate={moment(
              startDate || startDateISOString(new Date()),
            ).toDate()}
            maxDate={moment(startDate || startDateISOString(new Date()))
              .add(45, 'days')
              .toDate()}
            value={endDate}
            label="To Date"
            onChange={date => {
              setEndDate(
                moment(date)
                  .set({
                    hour: 23,
                    minute: 59,
                    second: 0,
                    millisecond: 0,
                  })
                  .toISOString(),
              );
            }}
            clear={false}
          />
        </div>
      </div>
    </BoxWrapper>
  </Wrapper>
);
export default withRouter(PlaceSearch);
